import { TinyLinkResolverService as Api } from '../../api/gen';
import { map, Observable } from 'rxjs';
import { TinyIdResult } from '../model/tiny-link/tiny-id-result';
import { Injectable } from '@angular/core';

@Injectable()
export class TinyLinkResolverService {
  constructor(private readonly tinyLinkResolver: Api) {}

  public fetchDataForTinyId(
    tinyId: string,
    tenantIdentifier: string,
  ): Observable<TinyIdResult> {
    return this.tinyLinkResolver
      .customerNotificationTinyUrlResolverGet(tinyId, tenantIdentifier)
      .pipe(map((dto) => TinyIdResult.fromDto(dto)));
  }
}
