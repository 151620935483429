import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { TenantInfoService } from '../util/tenant-info.service';
import { TinyLinkResolverService } from '../services/tiny-link-resolver.service';

@Component({
  selector: 'app-resolver',
  templateUrl: './resolver.component.html',
  styleUrls: ['./resolver.component.scss'],
  providers: [TinyLinkResolverService],
})
export class ResolverComponent {
  protected displayError = false;
  constructor(
    activatedRoute: ActivatedRoute,
    private readonly tenantService: TenantInfoService,
    private readonly tinyResolver: TinyLinkResolverService,
    private readonly router: Router,
  ) {
    activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      const inquiryId = params['inquiryId'];
      const tenantId = params['tenantId'];
      if (!inquiryId || !tenantId) {
        this.fetchTinyIdAndRedirectToJoin();
      } else {
        this.navigateToJoinComponent(inquiryId, tenantId);
      }
    });
  }

  private fetchTinyIdAndRedirectToJoin() {
    // Get the tinyId from the path by trimming the leading slash ("/{tinyId} -> "{tinyId}")
    const tinyId = this.router.url.slice(1);
    const tenantIdentifier = this.tenantService.getTenantIdentifier();
    if (!tinyId || !tenantIdentifier) {
      this.router.navigate(['/not-found']).then();
      return;
    }
    this.tinyResolver.fetchDataForTinyId(tinyId, tenantIdentifier).subscribe({
      next: (data) => {
        this.navigateToJoinComponent(data.inquiryId, data.tenantId);
      },
      error: () => {
        this.displayError = true;
      },
    });
  }

  private navigateToJoinComponent(inquiryId: string, tenantId: string): void {
    this.router
      .navigate(['/join'], {
        queryParams: {
          inquiryId: inquiryId,
          tenantId: tenantId,
        },
      })
      .then();
  }
}
