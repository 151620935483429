import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AnalyticsService } from './api/analytics.service';
import { ApplicationSettingsService } from './api/applicationSettings.service';
import { AppointmentService } from './api/appointment.service';
import { AttachmentService } from './api/attachment.service';
import { AuthenticationTokenService } from './api/authenticationToken.service';
import { BrandingColorService } from './api/brandingColor.service';
import { CompanyService } from './api/company.service';
import { FeatureTrackingService } from './api/featureTracking.service';
import { InquiryAccessService } from './api/inquiryAccess.service';
import { InquirySummaryService } from './api/inquirySummary.service';
import { InvitationStatusService } from './api/invitationStatus.service';
import { LiveChatService } from './api/liveChat.service';
import { LiveChatTranslationService } from './api/liveChatTranslation.service';
import { PingService } from './api/ping.service';
import { SyncAppointmentService } from './api/syncAppointment.service';
import { TinyLinkResolverService } from './api/tinyLinkResolver.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
