import { Component, signal } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TenantInfoService } from '../../util/tenant-info.service';
import { InvitationStatusService } from '../../services/invitation-status.service';
import { InquiryAccessService } from '../../services/inquiry-access.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InquiryOverviewComponent } from '../inquiry-overview/inquiry-overview.component';
import { InquiryAccessType } from '../../model/inquiry/inquiry-access-type';
import { InquiryAccessTokenComponent } from '../inquiry-access-token/inquiry-access-token.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    MatProgressSpinner,
    InquiryOverviewComponent,
    InquiryAccessTokenComponent,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  providers: [InquiryAccessService, InvitationStatusService],
})
export class OverviewComponent {
  protected isLoading$ = signal(true);
  protected accessType$ = signal<InquiryAccessType>(InquiryAccessType.Public);

  protected inquiryId: string;
  protected tenantId: string;
  protected initialAccess: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    tenantInfoService: TenantInfoService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly invitationStatusService: InvitationStatusService,
    private readonly inquiryAccessService: InquiryAccessService,
  ) {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.inquiryId = params['inquiryId'];
        this.tenantId = params['tenantId'];
        this.initialAccess = params['initialAccess'] ?? false;

        this.invitationStatusService
          .sendSmsInvitationClicked(this.inquiryId, this.tenantId)
          .subscribe();

        this.refresh();
      });
  }

  private refresh() {
    if (this.inquiryId) {
      this.inquiryAccessService.getInquiryAccessInfo(this.inquiryId).subscribe({
        next: (res) => {
          this.accessType$.set(res.accessType);
          this.isLoading$.set(false);
        },
        error: () => {
          this.snackBar.open(
            $localize`Konnte Daten zur Anfrage nicht laden, bitte versuchen Sie es später erneut!`,
            $localize`Ok`,
            { duration: 5_000 },
          );
        },
      });
    }
  }

  protected readonly InquiryAccessType = InquiryAccessType;
}
