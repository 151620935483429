import { TinyIdResultDto } from '../../../api/gen';

export class TinyIdResult {
  inquiryId?: string;
  tenantId?: string | null;

  static fromDto(dto: TinyIdResultDto): TinyIdResult {
    return {
      inquiryId: dto.inquiryId,
      tenantId: dto.tenantId,
    } as TinyIdResult;
  }
}
